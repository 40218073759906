<template>
  <div class="container">
    <b-col>
      <b-row>
        <b-col cols="12" class="text-right mt-2">
          <date-picker 
            v-model="range" 
            type="date" 
            format="YYYY-MM-DD" 
            value-type="YYYY-MM-DD"
            placeholder="Tarih Seçin"
            range-separator=" / "
            confirm 
            range 
            class="datePicker"
            ref="datePicker"
            confirm-text='Tamam'></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" md="2" v-for="atik_tur in homeMiktarTurGroupBox" :key="atik_tur.atik_ad" class="mini_box">
          <div class="mini_box_bg">
            <div class="atik_ad_mini_box">{{atik_tur.atik_ad}}</div>
            {{atik_tur.miktar ? atik_tur.miktar : 0}} Kg
          </div>
        </b-col>
      </b-row>
      
      <b-row>
        <b-col cols="12" md="6">
          <area-chart
            :data="c_data" 
            :colors="['#007bff', '#666']" 
            label="kg"
            lang="tr"
            class="mt-5 mb-5" />
        </b-col>
        <b-col cols="12" md="6">
          <column-chart
            :data="homeMiktarTurGroup" 
            :colors="['#007bff', '#666']" 
            lang="tr"
            class="mt-5 mb-5" />
        </b-col>
      </b-row>
      
      <!-- <b-row>
        <b-col sm="6" class="mb-5 d-none">
          <b-table striped hover :fields="fields_firma" :items="rapor_home_firma">
            <template v-slot:cell(firma_unvan)="data">
              <b-button class="button" :to="{name: 'firmaDetay', params:{firma_id: data.item.firma_id}}" variant="link">
                {{data.item.firma_unvan}}
              </b-button>
            </template>
          </b-table>
        </b-col>

        <b-col sm="6" class="mb-5 d-none">
          <b-table striped hover :fields="fields_user" :items="rapor_home_user">
            <template v-slot:cell(user_adsoyad)="data">
              <b-button class="button" :to="{name: 'kullaniciDetay', params:{id: data.item.user_id}}" variant="link">
                {{data.item.user_adsoyad}}
              </b-button>
            </template>
            <template v-slot:cell(islem_sayisi)="data">
              <b-button class="button" :to="{name: 'toplanan-atiklar', params:{id: data.item.user_id}}" variant="link">
                {{data.item.islem_sayisi}}
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row> -->

      <h6><strong>ALINMAYANLAR</strong></h6>
      <b-table responsive :fields="fields_firma_geciken" :items="firmalar" class="mb-5">
        <template v-slot:cell(ilgi)="data">
            <b-badge 
            v-if="!data.item.ilgi" 
            size="sm" 
            variant="danger"
            :to="{name: 'firmaDetay', params:{firma_id: data.item.firma_id}}"><b-icon-search></b-icon-search></b-badge>
        </template>
      </b-table>

      <h6 style="font-weight: 600;">TOPLAYICILAR</h6>
      <b-table v-if="users" responsive :fields="fields_users" :items="users">
        <template v-slot:cell(firma_sey)="data">
          <b-badge 
            :to="'/KullaniciDetay/' + data.item.user_id" 
            class="ml-1" 
            variant="primary"
            size="sm"><b-icon-search></b-icon-search></b-badge>
        </template>
      </b-table>
    </b-col>
  </div>
</template>

<script>
//import Swal from 'sweetalert2';
export default {
  data(){
    return{
      fields_firma: [
        {
          "key" : "firma_unvan",
          "label" : "Toplama Noktası"
        },
        {
          "key" : "miktar",
          "label" : "Toplam Miktar",
          "tdClass" : "text-right",
          "thClass" : "text-right"
        },
        {
          "key" : "islem_sayisi",
          "label" : "İşlem",
          "tdClass" : "text-right",
          "thClass" : "text-right"
        },
      ],
      fields_user: [
        {
          "key" : "user_adsoyad",
          "label" : "Ad Soyad"
        },
        {
          "key" : "miktar",
          "label" : "Toplam Miktar",
          "tdClass" : "text-right",
          "thClass" : "text-right"
        },
        {
          "key" : "islem_sayisi",
          "label" : "İşlem",
          "tdClass" : "text-right",
          "thClass" : "text-right"
        },
      ],
      fields_firma_geciken: [
        {
          "key": "firma_id",
          "label": "Id",
          "thStyle": {width: '50px'}
        },
        {
          "key" : "firma_unvan",
          "label" : "Toplama Noktası"
        },
        {
          "key" : "user_adsoyad",
          "label" : "Toplayıcı"
        },
        {
          "key" : "ilgi",
          "label" : "",
          "thStyle": { width: '50px'},
        },
      ],
      fields_users : [
        {
          "key": "user_id",
          "label": "Id",
          "thStyle": {width: '50px'}
        },
        {
            "key" : "user_adsoyad",
            "label" : "Ad Soyad",
            "thStyle": { width: '150px'},
            "sortable": "true"
        },
        {
            "key" : "user_plaka",
            "label" : "Plaka",
            "thStyle": { width: '150px'},
            "sortable": "true"
        },
        // {
        //     "key" : "user_eposta",
        //     "label" : "E-Posta",
        //     "thStyle": { width: '300px'},
        //     "sortable": "true"
        // },
        {
            "key" : "user_tel",
            "label" : "Telefon",
            "thStyle": { width: '150px'}
        },
        {
            "key" : "miktar",
            "label" : "T.Miktar",
            "thStyle": { width: '150px'}
        },
        {
            "key" : "gidilecek",
            "label" : "Gidilecek",
            "thStyle": { width: '50px'}
        },
        {
            "key" : "gidilen",
            "label" : "Gidilen",
            "thStyle": { width: '50px'}
        },
        {
            "key" : "user_basari",
            "label" : "Başarı%",
            "thStyle": { width: '80px'}
        },
        {
            "key" : "firma_sey",
            "label" : "",
            "thStyle": { width: '50px'}
        },
      ],
      rangeChange: null,
    }
  },

  mounted(){
    this.$store.dispatch("rapor_home_firma", this.days);
    this.$store.dispatch("rapor_home_user", this.days);
    this.$store.dispatch("rapor_home_chart", this.days);
    this.$store.dispatch("homeMiktarTurGroup", this.days);
    this.$store.dispatch("users");
  },

  computed : {
    rapor_home_firma(){
      return this.$store.state.rapor_home_firma;
    },
    rapor_home_user(){
      return this.$store.state.rapor_home_user;
    },
    c_data(){
      return this.$store.state.rapor_home_chart.reduce(function(map, obj) {
          map[obj.gun] = obj.miktar * 1;
          return map;
      }, {});
    },
    homeMiktarTurGroup(){
      return this.$store.state.homeMiktarTurGroup;
    },
    homeMiktarTurGroupBox(){
      var tur = [];
      Object.keys(this.homeMiktarTurGroup).forEach(item => {
        tur.push({
          atik_ad: item,
          miktar: this.homeMiktarTurGroup[item]
        })
      });
      return tur;
    },
    atik_turleri(){
      let turler = this.$store.state.atik_turleri;
      for(var x=0; x < turler.length; x++){
        let a = this.atik_alimlari.filter(c => c.atik_id == turler[x].atik_id);
        turler[x].miktar = a.reduce((sum, item) => sum + parseFloat(item.atik_alim_miktar), 0);
      }
      return turler;
    },
    atik_alimlari(){
      return this.$store.state.atik_alimlari;
    },
    firmalar(){
      return this.$store.state.firmalar.filter(c => !c.ilgi);
    },
    users(){
      return this.$store.state.users;
    },
    days(){
      return this.$store.state.days;
    },
    range: {
      get(){
        return this.rangeChange === null ? [this.days.start, this.days.finish] : this.rangeChange;
      },
      set(val){
        this.rangeChange = val;
      }
    }
  },

  methods: {
    
  },

  watch:{
    rangeChange(a){
      var deger = {
        start: a[0],
        finish: a[1],
      }
      this.veriGuncelle(deger);
    }
  }
}
</script>


<style scoped>
.button{
  color: #333;
  padding: 0;
}
.mini_box {
    margin-top: 30px;
    position: relative;
}
.mini_box_bg {
    background-color: #f1f1f1;
    padding: 15px;
}
.atik_ad_mini_box {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #047afb;
}
@media (max-width: 767px){
  .datePicker{
    width: 100%;
  }
}
</style>